// Root Reducer

import { combineReducers } from "redux";
import newLeadReducers from "./newLeadReducers";
import newStreetReducers from "./newStreetReducer";

export let rootReducer = combineReducers({
    newLead: newLeadReducers,
    street: newStreetReducers,
});

export default rootReducer;
