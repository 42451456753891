import NewLeadRepository from "./NewLeadRepository";
import StreetRepository from "./StreetRepository";

const repositories = {
    newLead: NewLeadRepository,
    street: StreetRepository,
};
// eslint-disable-next-line import/prefer-default-export
export const RepositoryFactory = {
    get: (name) => repositories[name],
};
