import React from "react";
import { Card, CardImg, Col, Container, Row } from "reactstrap";
import logo from "../assets/img/logo.png";
import img1 from "../assets/img/service.png";
import img2 from "../assets/img/bulb.png";
import img3 from "../assets/img/powerIcon.png";
import img4 from "../assets/img/rupee.png";
import img5 from "../assets/img/Setting.png";
import img6 from "../assets/img/cap.png";

function WaaromInfraroodver() {
    return (
        <div className="bg-site-gray py-5">
            <Container
                fluid="lg"
                className="text-center text-site-primary pb-3"
            >
                <img src={logo} width="100" className="mb-4" />
                <h4 className="text-site-primary font-weight-bold">
                    Waarom infraroodverwarming van ClimateMasters?
                </h4>{" "}
                <hr className="hrW-25  border-2 " />
                <Row className="extra_padding">
                    <Col sm="6" md="4">
                        <Card className="border-0 text-center bg-transparent">
                            <img
                                src={img1}
                                width="70"
                                height="70"
                                className="mx-auto"
                            />
                            <p className="font-weight-bold my-2">Service</p>
                            <p className="font-weight-light text-site-dark">
                                <small>
                                    Uitmuntende service, een persoonlijke aanpak
                                    en showroom aan huis
                                </small>
                            </p>
                        </Card>
                    </Col>
                    <Col sm="6" md="4">
                        <Card className="border-0 text-center bg-transparent">
                            <img
                                src={img2}
                                width="70"
                                height="70"
                                className="mx-auto"
                            />
                            <p className="font-weight-bold my-2">
                                Beste Oplossingen
                            </p>
                            <p className="font-weight-light text-site-dark">
                                <small>
                                    Een unieke aanpak die leidt tot de beste
                                    oplossing voor iedere situatie
                                </small>
                            </p>
                        </Card>
                    </Col>
                    <Col sm="6" md="4">
                        <Card className="border-0 text-center bg-transparent">
                            <img
                                src={img3}
                                width="70"
                                height="70"
                                className="mx-auto"
                            />
                            <p className="font-weight-bold my-2">
                                Duurzaamheid
                            </p>
                            <p className="font-weight-light text-site-dark">
                                <small>
                                    Een duurzaam product dat bijdraagt aan het
                                    verminderen van het energieverbruik
                                </small>
                            </p>
                        </Card>
                    </Col>
                    <Col sm="6" md="4">
                        <Card className="border-0 text-center bg-transparent">
                            <img
                                src={img4}
                                width="70"
                                height="70"
                                className="mx-auto"
                            />
                            <p className="font-weight-bold my-2">
                                Besparing tot 70%
                            </p>
                            <p className="font-weight-light text-site-dark">
                                <small>
                                    Met Infraroodverwarming bespaar je tot 70%
                                    op stookkosten.
                                </small>
                            </p>
                        </Card>
                    </Col>
                    <Col sm="6" md="4">
                        <Card className="border-0 text-center bg-transparent">
                            <img
                                src={img5}
                                width="70"
                                height="70"
                                className="mx-auto"
                            />
                            <p className="font-weight-bold my-2">
                                Professionele Installatie{" "}
                            </p>
                            <p className="font-weight-light text-site-dark">
                                <small>
                                    Wij werken met eigen gediplomeerde monteurs
                                </small>
                            </p>
                        </Card>
                    </Col>
                    <Col sm="6" md="4">
                        <Card className="border-0 text-center bg-transparent">
                            <img
                                src={img6}
                                width="70"
                                height="70"
                                className="mx-auto"
                            />
                            <p className="font-weight-bold my-2">Veiligheid </p>
                            <p className="font-weight-light text-site-dark">
                                <small>
                                    Onze infraroodpanelen zijn voorzien van
                                    veiligheidskeurmerken zoals CE en VDE
                                </small>
                            </p>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default WaaromInfraroodver;
