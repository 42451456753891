import Repository from "./Repository";
import StreetRepository from "./StreetRepository";

const NEW_LEAD = "/api/sponsors/2092/leads";
export default {
    getStreet(payload) {
        // console.log(payload, "payload in Repostory");
        return StreetRepository.get(
            `?postcode=${payload.zip}&number=${payload.house_number}`
        );
    },
    createNewLead(payload) {
        return Repository.post(`${NEW_LEAD}`, payload);
    },
};
