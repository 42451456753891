import { RepositoryFactory } from "../../repository/RepositoryFactory";
var LeadRepo = RepositoryFactory.get("newLead");
export const createLoader = (val) => async (dispatch) => {
    dispatch({
        type: "CREATE_LOADER",
        payload: val,
    });
};
export const createNewLead =
    (payload, onSuccess = () => {}) =>
    async (dispatch) => {
        dispatch(createLoader(true));
        try {
            let { data } = await LeadRepo.createNewLead(payload);
            if (data) {
                dispatch({
                    type: "CREATE_RESPONSE",
                    payload: data,
                });
                onSuccess();
                dispatch(createLoader(false));
            } else {
                dispatch(createLoader(false));
            }
        } catch (e) {
            console.log(e.response.data.error);
            onSuccess();
            dispatch(createLoader(false));
        }
    };
