const initialState = {
    newStreet: null,
};
const newStreetReducers = (state = initialState, action) => {
    switch (action.type) {
        case "NEW_STREET":
            return {
                ...state,
                newStreet: action.payload,
            };
        default:
            return state;
    }
};
export default newStreetReducers;
