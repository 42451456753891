import { RepositoryFactory } from "../../repository/RepositoryFactory";
var newStreet = RepositoryFactory.get("street");

export const getStreet =
    (payload, onSuccess = () => {}) =>
    async (dispatch) => {
        try {
            let { data } = await newStreet.getStreet(payload);
            if (data) {
                dispatch({
                    type: "NEW_STREET",
                    payload: data,
                });
                onSuccess();
            }
        } catch (e) {
            console.log(e.response.data.error);
        }
    };
