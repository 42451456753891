import axios from "axios";
const baseDomain =
    "https://cors-platform.herokuapp.com/https://leadgen.republish.nl";
const baseURL = `${baseDomain}`;
let axiosObj;
axiosObj = axios.create({
    baseURL,
    headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": baseURL,
        Authorization:
            "Basic MTg1OmFiODIyMWQ0YTMxNzBkODk1NDI4ODA0NTlhYmY3OTgxN2FlMzY3YzI=",
    },
});

export default axiosObj;
